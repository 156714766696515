.avatar {
  @apply relative inline-flex h-10 w-10 shrink-0;
}

.avatar > img {
  @apply h-full w-full object-cover object-center;
}

.avatar > .is-initial {
  @apply flex h-full w-full items-center justify-center font-medium;
}
